<template>
  <div>

    <section>      
      <b-container class="mt-5 mb-5 pt-2">

        <b-row class="mb-4">
          <b-col>
            <h1>Mexicana Emprende: Directorio</h1>
          </b-col>
        </b-row>


        <b-row class="mb-4">
          <b-col>
            <div>
   
                <b-button href="/pceme" class="strong m-1">Todos</b-button>
                <b-button
                  v-for="(item,i) in arraySectors" :key="i"
                  :href="`/pceme?sector=${item.title}`"
                  :style="`background:${item.color}`"
                  class="strong m-1"
                >
                  {{ item.name }}
                </b-button>
            </div>
          </b-col>
        </b-row>


        <b-row v-if="!cfg.query_sector" class="mb-4">
          <b-col v-for="(item,i) in directory" :key="i" cols lg="4" md="4" sm="6" xs="12">
            <div class="card-directory-pceme px-0 my-3 grey lighten-4 box-10 z-depth-3">
              <b-img
                fluid-grow
                :src="`${ item.photo ? path.storage_files + item.photo.url : path.storage_files + '/uploads/pceme_f8aeff44c9.jpg' }`"
                :alt="`${ item.legalName }`"
                :title="`${ item.legalName }`"
                class="z-depth--1 box-10"
              ></b-img>
              <h4 class="mt-3 mb-0 mx-4">{{item.legalName }}</h4>
              <div class="tags mx-4">
                  <b-badge class="blue-grey darken-2">
                  <i class="fa-solid fa-user"></i>
                  {{ item.founder }}
                </b-badge>
                <b-badge :style="`background-color:${item.sector.color}`" class="mx-1">
                  <i class="fa-solid fa-tag"></i>
                  {{ item.sector.name }}
                </b-badge>
              </div>
              <b-col class="mx-3 mt-2 text-right">
                <b-button 
                  class="mb-4 mt-3 mx-2"
                  size="sm"
                  v-b-modal="'modalCardPceme'" 
                  itemPceme="'item'" 
                  @click="sendInfo(item)"
                  :style="`background-color:${item.sector.color}`"
                >
                  Contactame
                </b-button>
              </b-col>
            </div>
          </b-col>
        </b-row>



        <b-row v-else class="mb-4">
          <b-col v-for="(item,i) in bySector" :key="i" cols lg="4" md="4" sm="6" xs="12">
            <div class="card-directory-pceme px-0 my-3 grey lighten-4 box-10 z-depth-3">
              <b-img
                fluid-grow
                :src="`${ item.photo ? path.storage_files + item.photo.url : path.storage_files + '/uploads/pceme_f8aeff44c9.jpg' }`"
                :alt="`${ item.legalName }`"
                :title="`${ item.legalName }`"
                class="z-depth--1 box-10"
              ></b-img>
              <h4 class="mt-3 mb-0 mx-4">{{item.legalName }}</h4>
              <div class="tags mx-4">
                  <b-badge class="blue-grey darken-2">
                  <i class="fa-solid fa-user"></i>
                  {{ item.founder }}
                </b-badge>
                <b-badge :style="`background-color:${item.sector.color}`" class="mx-1">
                  <i class="fa-solid fa-tag"></i>
                  {{ item.sector.name }}
                </b-badge>
              </div>
              <b-col class="mx-3 mt-2 text-right">
                <b-button 
                  class="mb-4 mt-3 mx-2"
                  size="sm"
                  v-b-modal="'modalCardPceme'" 
                  itemPceme="'item'" 
                  @click="sendInfo(item)"
                  :style="`background-color:${item.sector.color}`"
                >
                  Contactame
                </b-button>
              </b-col>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <a target="_blank" href="https://sre.gob.mx/component/phocadownload/category/5-transparencia-y-acceso-a-la-informacion?download=1278:directorio-mujer-emprende-2022">Aviso de Privacidad para el "Directorio Mexicana Emprende"</a>
          </b-col>
        </b-row>



        <b-modal
          id="modalCardPceme"
          size="lg"
          :title="`${selectedItem.legalName}`"
          ok-only
          scrollable
          centered
          :style="`padding:20px`"
        >

          <div class="mx-4 my-4">

            <b-img
            v-if="selectedItem.photo"
              fluid-grow
              :src="`${ path.storage_files + selectedItem.photo.url }`"
              :alt="`${ selectedItem.legalName }`"
              :title="`${ selectedItem.legalName }`"
              class="z-depth-3 box-10"
            ></b-img>

            <h5 class="mt-4 mb-2 blue-grey-text text-darken-3">By {{selectedItem.founder }}</h5>
            <vue-simple-markdown :source="`${selectedItem.description}`"></vue-simple-markdown>


            <div class="" v-for="(item, i) in selectedItem.SocialMedia" :key="i">
              <span v-if="item.type === 'facebook'">
                <a :href="`https://fb.mx/${item.title}`" target="_blank" class="btn blue darken-3 text-white m-1">
                  <i class="fa-brands fa-facebook-f"></i> {{ item.type }}
                </a>
              </span>
              <span v-if="item.type === 'twitter'">
                <a :href="`https://twitter.com/${item.title}`" target="_blank" class="btn light-blue lighten-1 text-white m-1">
                  <i class="fa-brands fa-twitter"></i> {{ item.type }}
                </a>
              </span>
              <span v-if="item.type === 'instagram'">
                <a :href="`https://www.instagram.com/${item.title}`" target="_blank" class="btn pink text-white m-1">
                  <i class="fa-brands fa-instagram"></i> {{ item.type }}
                </a>
              </span>
              <span v-if="item.type === 'whatsapp'">
                <a :href="`https://wa.me/${item.title}`" target="_blank" class="btn green accent-4 text-white m-1">
                  <i class="fa-brands fa-whatsapp"></i> {{ item.type }}
                </a>
              </span>
              <span v-if="item.type === 'tiktok'">
                <a :href="`https://www.tiktok.com/@${item.title}`" class="btn blue-grey darken-4 text-white m-1">
                  <i class="fa-brands fa-tiktok"></i> {{ item.type }}
                </a>
              </span>
              <span v-if="item.type === 'email'">
                <a :href="`mailto:${item.title}`" class="btn blue-grey darken-3 text-white m-1">
                  <i class="fa-solid fa-envelope"></i> {{ item.title }}
                </a>
              </span>
              <span v-if="item.type === 'gmap'">
                <a :href="`${item.title}`" target="_blank" class="btn blue-grey darken-1 text-white m-1">
                  <i class="fa-solid fa-map-location-dot"></i> {{ item.type }}
                </a>
              </span>
              <span v-if="item.type === 'phone'">
                <a :href="`tel:${item.title}`" class="btn blue-grey darken-1 text-white m-1">
                  <i class="fa-solid fa-phone"></i> {{ item.title }}
                </a>
              </span>
              <span v-if="item.type === 'website'">
                <a :href="`${item.title}`" target="_blank" class="btn blue-grey darken-1 text-white m-1">
                  <i class="fa-solid fa-link"></i> {{ item.title }}
                </a>
              </span>

              <p v-if="item.type === 'address'">
                <a :href="`https://www.google.es/maps/place/${item.title}`" class="btn blue-grey darken-1 text-white m-1" target="_blank">
                  {{ item.title }}
                </a>
              </p>


            </div>


          </div>


        </b-modal>


      </b-container>
    </section>
  </div>
</template>

<script>
import DataDirectoryPceme from "@/services/data-pceme";

export default {
  name: 'HomePage',

  metaInfo: {
  title: 'Instituto de los Mexicanos en el Exterior',
  titleTemplate: null
},

  components: {
    DataDirectoryPceme,
  },

  data() {
    return {

      directory: [],
      bySector: [],
      arraySectors: [],
      selectedItem: {
        photo: [],
        sector: [],
      },

      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
        query_sector: this.$route.query.sector,
      },
    
    }
  },

  mounted() {
    document.title = 'Instituto de los Mexicanos en el Exterior';
    this.directoryPceme();
    this.directorySectors();
  },

  methods: {

    directoryPceme() {
      DataDirectoryPceme.getAllDirectory()
        .then(response => {
          this.directory = response.data;
          console.log("🚀 ~ file: DirectoryPceme.vue ~ line 90 ~ directoryPceme ~ this.directory", this.directory)
        })
        .catch(e => {
          console.log(e);
        });
    },



    directorySectors() {
      DataDirectoryPceme.getAllSectors()
        .then(response => {
          this.arraySectors = response.data;
          console.log("🚀 ~ file: DirectoryPceme.vue ~ line 221 ~ directorySectors ~ this.arraySectors", this.arraySectors)
        })
        .catch(e => {
          console.log(e);
        });
    },


    /*
    directorybySector() {
      DataDirectoryPceme.getDirectoryBySector()
        .then(response => {
          this.bySector = response.data;
          console.log("🚀 ~ file: DirectoryPceme.vue ~ line 169 ~ directorybySector ~ this.bySector", this.bySector)
        })
        .catch(e => {
          console.log(e);
        });
    },
    */


    sendInfo(item) {
        this.selectedItem = item;
        console.log("🚀 ~ file: DirectoryPceme.vue ~ line 111 ~ sendInfo ~ this.selectedItem", this.selectedItem)
    }


  },


  /*
   * Configuracion Inicial
   */
   async created() {
    await fetch(
      this.path.endpoint + '/undertakes/?sector.title='+ this.cfg.query_sector
    ).then(response => response.json())
    .then(data => {
      this.bySector = data;
      console.log("🚀 ~ file: DirectoryPceme.vue ~ line 197 ~ created ~ this.bySector", this.bySector)
      console.log("🚀 ~ file: DirectoryPceme.vue ~ line 194 ~ created ~ this.cfg.query_sector", this.cfg.query_sector)
    })
    .catch(err => {
      console.error(err);
    });
  },
  /* End Ajuste */


}
</script>