import http from "@/services/http-common";

class DataDirectoryPceme {

    getAllDirectory() {
        return http.get('/undertakes/');
    }
    

    getDirectory(id) {
        return http.get(`/undertakes/${id}`);
    }

    //getDirectoryByRepresentation() {
    //    return http.get(`/undertakes/?level=1&level=2&level=9`);
    //}

    getDirectoryBySector(sector) {
        return http.get(`/undertakes/?sector.title=${sector}`);
    }

    getAllSectors() {
        return http.get('/sectors/');
    }


}

export default new DataDirectoryPceme();
